import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg2 from '../assets/img/mg12.png'


import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article7 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  The mission of ARISE is well-spread through the TWI Innovation Network!
                
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 15/05/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  30px 20px 0px'}} src={blogimg2} title=""  width="460" height="291" />
               <p>We are thrilled to announce that TWI Global has released a press release highlighting the ARISE project, funded under the Horizon Europe programme. 
               </p>
               <p>ARISE is set to transform automation in the energy and agriculture sectors through innovative AI and robotics solutions. </p>



  <p>With TWI Hellas, part of the TWI partnership, as the Project Coordinator, ARISE will focus on developing a reconfigurable robotic manipulator for tasks such as solar system installation and hydroponic farming. This initiative promises significant advancements in efficiency, productivity, and sustainability.
  </p>
<p>Read the <a href='https://www.twi-global.com/media-and-events/press-releases/2024/arise-making-a-step-change-to-solar-and-hydroponic-farms'>full press</a> release on TWI Global’s website for more details.
</p>
                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article7