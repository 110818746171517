import React from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Mapchart from '../map.js'
import signature from "../signaturejs.js";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ContactForm from './contact'
import DarkFooter from "../../components/Footers/DarkFooter.js";
import cons from '../cons.png'
import test from '../../assets/img/test2.svg'
import { HashLink } from "react-router-hash-link";
import CustomForm from '../../components/SubscribeForm'
import blogimg6 from '../../assets/img/smart.jpg'
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import CenterMode from "../../components/carousel";


const url = "https://gmail.us21.list-manage.com/subscribe/post?u=f794e711ea6323a9cd6e8281b&amp;id=3e14056d02&amp;f_id=0063f5e6f0";

const SimpleForm = () => <MailchimpSubscribe url={url}/>

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
        <LandingPageHeader i />
        <section className="section section-about-us" id='mission'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#7dbf6d'}}>Our Mission</h2>
                <h5 className="description" style={{textAlign:'justify',color:'#303030'}}>
                ARISE aims to realise a robotic platform that focuses on two crucial industrial sectors that can drive the European Green Deal progress, namely Energy and Agriculture. ARISE will be driven by the needs of Solar Panel installation and maintenance and the harvesting of hydroponic lettuce. It will be validated on five highly challenging Use Case Scenarios, including but not limited to, picking up and moving Solar Panels in collaboration with human workers, transplanting delicate lettuce pieces and repairing solar panel back-sheets with silicon-based sealants. This set of scenarios involves extremely diverse requirements in terms of long-sequence task accomplishment, complex, contact-rich manipulation and physical Human-Robot Interaction.                </h5>
              </Col>
            </Row>
            </Container>
            </section>
            <div id='consortium'>
            <section className="section section-about-us" id='consortium2' style={{backgroundColor:'#a3d098'}}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
                <h2 className="title" style={{color:'#ffffff'}}>Our Consortium</h2>
                <h5 className="description" style={{textAlign:'justify',color:'#ffffff'}}>
                Our consortium brings together partners from eight countries namely Greece, Italy, Spain, Germany, France, Croatia, Cyprus and UK: Four research centres (CERTH, THL, CEA and CSIC), four universities (SSSA, TUDa, HMU, ESS), one highly prominent industrial company (ALS), five high-tech SMEs (BBL, PLX, UBT, PRO, TEL) and two end-users (ELVAN, JEPCO). ARISE partners are all extremely prominent in their respective fields, contributing to the constant evolution of autonomous robots by leveraging the capabilities of robotic systems combined with the technological innovations of AI, hardware, and network technologies and forming modular systems to speed up their integration in industrial processes. 
               
                </h5>
                <br/>
               
              </Col>
              
            </Row>
            </Container>
            </section></div>
            <section className="section section-about-us" id='consortium' style={{backgroundColor:'#ffffff'}}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10" >
                <CenterMode/>
               
              </Col>
              
            </Row>
            </Container>
            </section>
            <section id='latestnews'>
            <div className="section section-team text-center" style={{backgroundColor:'#3a3a3a'}}>
            <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
              <h2 className="title" style={{color:'#7dbf6d'}}>Latest News</h2>
      
           
              </Col>
              
            </Row>
            </Container>
            </div>
            </section>

        <section id='mailchimp'>
        <div className="section section-team text-center" style={{backgroundColor:'#88bf7b'}}>
        <Container>
            <Row>

              <Col className="ml-auto mr-auto text-center" md="8">
           <div id='rect'>
              <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (

      <div  id='simple' style={{padding:'40px'}}>
        <h5>Subscribe to our newsletter and stay updated.</h5>
        <SimpleForm  onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div style={{ color: "#1e646c",position:'absolute' }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" ,position:'absolute'}} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "#1e646c" }}>Subscribed !</div>}
      </div>
    )}
  />
  </div>
              </Col>
            </Row>
            </Container>
        </div>
        </section>
       
        <ContactForm/>
        <DarkFooter />

      </div>
    </>
  );
}

export default LandingPage;
