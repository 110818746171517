import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg from '../assets/img/article9.png'


import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article9 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  ARISE shines at the 88th Thessaloniki International Fair
                
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
            01/10/2024

					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  30px 20px 0px'}} src={blogimg} title=""  width="230" height="291" />
               <p>The Centre for Research and Technology Hellas – CERTH participated in the 88th Thessaloniki International Fair from 7-15 September, through a number of innovative technological exhibits. CERTH was located at the stand of the General Secretariat for Research and Innovation, at the Hellenic Ministry of Development and Investment’s booth. Visitors could see up close and be informed -among other cutting-edge scientific achievements- at CERTH’s booth about the ARISE project. 
               </p>
 

  <p>For more information, have a look at the website of the fair: 
  <a href='https://thessalonikifair.gr/en '> https://thessalonikifair.gr/en</a>.</p>

                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article9