import React from 'react'
import $ from 'jquery';

$(document).ready(function() {
	$('svg path').addClass('animate');
});

const Signature = () => {
    
   
   

      return  ( <div class="signature">
      <svg width="387px" height="87px" viewBox="0 0 1593 450" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="signature" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="bevel">
              <path  d="M282,233.134328 C283.333333,205.800995 320.333333,170.800995 393,128.134328 C465.666667,85.4676617 516,65.1343284 544,67.1343284 C557.333333,76.4676617 492.333333,124.800995 349,212.134328 C205.666667,299.467662 99.6666667,364.467662 31,407.134328 C207,288.467662 332.333333,219.467662 407,200.134328 C433.666667,193.467662 438,199.800995 420,219.134328 C393,248.134328 385,250.134328 377,250.134328 C369,250.134328 357,241.134328 377,233.134328 C469,202.467662 532.333333,176.134328 567,154.134328 C619,121.134328 653,108.134328 648,103.134328 C643,98.1343284 614,118.134328 563,149.134328 C512,180.134328 430,229.134328 407,246.134328 C384,263.134328 411,261.134328 437,250.134328 C463,239.134328 521,207.134328 573,193.134328 C625,179.134328 572,208.134328 558,215.134328 C544,222.134328 528,230.134328 528,235.134328 C528,240.134328 531,247.134328 547,233.134328" id="Path" stroke="#333333" stroke-width="7"></path>
              <path  d="M663.753378,248.412507 C657.753378,217.079174 722.753378,174.74584 858.753378,121.412507 C1062.75338,41.4125072 1091.75338,88.4125072 930.753378,168.412507 C769.753378,248.412507 660.753378,298.412507 565.753378,330.412507 C470.753378,362.412507 503.753378,345.412507 586.753378,312.412507 C724.086712,261.74584 832.086712,229.412507 910.753378,215.412507 C1047.42004,191.412507 1048.42004,209.74584 913.753378,270.412507 C854.420045,293.74584 824.420045,304.079174 823.753378,301.412507 C822.753378,297.412507 831.753378,300.412507 905.753378,282.412507 C991.588964,256.687512 1075.58896,234.687512 1157.75338,216.412507 C1281,189 1449.75338,164.412507 1531.75338,164.412507" id="Path-2" stroke="#333333" stroke-width="7"></path>
          </g>
      </svg>
      </div> )};
      export default Signature;