/*eslint-disable*/
import React from "react";
import logo from "../../assets/img/tubers_logo.svg"
// reactstrap components
import { Container } from "reactstrap";
import eu from '../../assets/img/logos/eu.jpg'
import arise from "../Navbars/arise-hor-white.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faLinkedinIn, faXTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons'



function DarkFooter() {
  return (
    <footer className="footer">
    
    <div class="container py-5" >

      <div class="row py-4">
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0"  style={{marginTop:'-15px'}}>
       
        
        <img src={arise} style={{width:'140px',padding:'0px 5px'}}></img>
      
        <hr/>
        <ul id='ulu' style={{color:'#dadada'}}>
            <li  style={{display:'block'}}>Starting date: 1st January 2024</li>
            <li  style={{display:'block'}}>Duration: 48 months </li>
            <li  style={{display:'block'}}>Coordinator: TWI Hellas</li>
            <li  style={{display:'block'}}>Consortium: 16 partners from 8 EU countries </li>
            <li  style={{display:'block'}}>More ARISE info at<a id='cord' target="_blank"  href='https://cordis.europa.eu/project/id/101135959'>CORDIS</a> </li>
            <li  style={{display:'block'}}><a id='cord' style={{padding:'0'}} target="_blank"  href=''>Zenodo</a> </li>
          </ul>

        </div>
        
        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0" id='marginfooter'>
          <h6 class="text-uppercase font-weight-bold mb-4" style={{fontSize:'30px'}}>Follow us</h6>
          <hr style={{width:'140px'}}/>
          <ul class="list-unstyled mb-0">
          
                     
            <li class="mb-2"> <a target="_blank"  href='https://www.linkedin.com/showcase/arise-robotics/about/'> <i id='social' className="fab fa-linkedin"></i></a></li>
            <li class="mb-2"> <a target="_blank"  href='https://www.youtube.com/channel/UCUr8HjFR0pQVNbHhU12mABw'> <i id='social' className="fab fa-youtube"></i></a></li>
            <li class="mb-2"> <a target="_blank"  href='https://twitter.com/ARISERobotics'> <i id='social' className="fab fa-twitter"></i></a></li>
          </ul>
          
        </div>
        <div class="col-lg-4 col-md-6 mb-lg-0" id='marginfooter'  style={{marginTop:'0px'}}>
          <img src={eu} style={{width:'60px',marginTop:'-8px',border:'1px solid whitesmoke'}}></img>
          <hr/>
          <p style={{color:'#dadada', fontSize:'1em'}}>This project has received funding from the European Union’s Horizon Europe programme under Grant Agreement N° 101135959.</p>
     
          <hr/>
          
        </div>
      </div>
    </div>
    <div class="bg-light py-4">
    <div class="container text-center">
        <p class=" mb-0 py-2" style={{color:'#7dbf6d'}}>© 2024 Arise. All rights reserved.</p>
      </div>
    </div>

    </footer>
  );
}

export default DarkFooter;


/*<a id='a'href="https://www.w3schools.com" style={{color:'#dadada',fontSize:'14px',cursor:'pointer'}}>Privacy policy</a>*/