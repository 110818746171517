import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg from '../assets/img/6news.jpg'


import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article6 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  Visiting the facilities of our partner JEPCO: Exciting takeaways!
                
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 23/07/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  30px 20px 0px'}} src={blogimg} title=""  width="460" height="291" />
               <p>We, representatives of the ARISE consortium, visited the JEPCO facility on July 2nd, a short ride from downtown Colchester. Jonathan, the plant operations manager, and Roberto from Essex University Robotics Lab welcomed us warmly and guided us through the facility, answering our questions.
               </p>
               <p>During our visit, we observed key operations, including transplanting, flume distribution, and harvesting. The facility's impressive output of over 50,000 lettuces per week was striking. The physical demands on workers, involving tasks like kneeling and treading in water, highlighted the potential impact of our project.</p>
<p>Our team gained valuable technical knowledge for the project. Mechanical engineers measured the infrastructure to inform the final platform design, while the ML team recorded video feeds for 3D reconstruction. We also experienced the physical requirements of handling lettuce firsthand. Additionally, we learned that Roberto has been working independently on the computer vision aspect for a year, potentially offering valuable insights to the consortium.</p>
<p>This productive visit and the subsequent brainstorming session will be crucial for the project's next steps.</p>

  <b>JEPCO Visit Takeaways:</b><br/><br/><p>What left us an impression:</p>
  <ul>
    
    <li>The output of production, over 50k lettuce in a week
    </li>
    <li>We realized how physically taxing it must be to work 8-hour shifts by observing the farm workers and transplanting some lettuce ourselves. This validates the impactfulness of the project we had envisioned from the beginning.
    </li>
  </ul>


<p>What we found useful for the project:</p>
  <ul>
    
    <li>Technical data for the ideal positioning of the robot platform, valuable video feed for 3d reconstruction purposes, etc.
    </li>
    <li>A feel for the force and dexterity needed to successfully outroot, transplant and cut lettuce during its growth phases.
    </li>
  </ul>

  <p>Something we didn’t know:</p>
  <ul>
    
    <li>Roberto is working on the computer vision part on his own for the better part of a year, maybe he has some useful insights to share with the consortium!
    </li>

  </ul>
                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article6