import React, { useState } from "react";
import { Fade } from "react-slideshow-image";

import 'react-slideshow-image/dist/styles.css'
import photo0 from '../../assets/img/ARISE_ShutterPhotos/1.jpg'
import photo1 from '../../assets/img/ARISE_ShutterPhotos/ARISE_shutterphoto2.png'
import photo2 from '../../assets/img/ARISE_ShutterPhotos/ARISE_shutterphoto3.png'
import photo3 from '../../assets/img/ARISE_ShutterPhotos/2.jpg'



const Slideshow = () => {
  const [previousIndex, setPreviousIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);

  const style = {
    textAlign: "center",
    padding: "200px 0",
    fontSize: "30px"
  };

  const properties = {
    autoplay: true,
    indicators: false,
    arrows:false,
    onChange: (previous, next) => {
      setPreviousIndex(previous);
      setNextIndex(next);
    }
  };

  return (
    <div >
      <div style={{height:'100vh'}}>
        <Fade {...properties}>
          <div >
            <img id='backimg' src={photo0}/>
          </div>
          <div>
          <img id='backimg' src={photo1}/>     
          </div>
          <div>
          <img id='backimg' src={photo2}/>
          </div>
          <div>
          <img id='backimg' src={photo3}/>
          </div>
        </Fade>
      </div>
     
    </div>
  );
};

export default Slideshow;