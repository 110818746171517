import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg6 from '../assets/img/5news.png'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article2 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  ARISE Project Featured in ADRA-e Brochure
                
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 20/04/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  30px 20px 0px'}} src={blogimg6} title=""  width="440" height="671" />
               <p>We are thrilled to announce that our Horizon Europe project, ARISE, has been included in the prestigious ADRA-e brochure. This inclusion highlights the innovative work being undertaken by our consortium, which is part of the AI, Data, and Robotics Co-programmed partnership.</p>
<p>
  <b>Key Highlights:</b>
  <ul>
    <li><b>Event:</b> ADRA-e joint launch event</li>
    <li><b>Date:</b> February 22nd
    </li>
    <li><b>Purpose:</b> To present new projects and the roles of various AI landscape actors supported by the European Commission.
    </li>
  </ul>
The brochure and the launch event aim to provide visibility for ARISE, showcasing our groundbreaking efforts in AI and robotics. During the event, our project coordinator presented a pre-recorded pitch, introducing ARISE to a wider community.
</p>
<p>
We are excited to be part of this initiative and look forward to making significant contributions to the field of AI.</p>
<p>For more details, visit <a href='https://adra-e.eu/sites/default/files/2024-03/CNECT_ERF2024_LR_002_6HTEUZvrKcJgbWlqwk9jbeTXHQ_103116.pdf'>ADRA-e brochure.</a></p>

              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article2