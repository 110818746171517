import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg4 from '../assets/img/4news.jpg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article2 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  ARISE Project Shines at ICRA 2024                 
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 18/05/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  30px 20px 0px'}} src={blogimg4} title=""  width="420" height="280" />
                <p>
                Our esteemed partner, UBITECH, showcased groundbreaking advancements of the ARISE project at the prestigious ICRA 2024 conference in Yokohama, Japan. Their work is pushing the boundaries of AI and robotics, paving the way for innovative solutions in solar power and beyond.</p>
<b>Conference Highlights:</b>
<ul>
  <li><b>Event:</b> ICRA 2024, the world’s largest robotics and automation conference.</li>
  <li><b>Dates:</b> 13th-17th May 2024.</li>
  <li><b>Location:</b> Yokohama, Japan.</li>
  <li><b>Details:</b> <a href='https://2024.ieee-icra.org/'>ICRA 2024</a></li>
</ul>
<p>
UBITECH disseminated and promoted the excellent work undertaken for both the ARISE and Arogi projects during the event.
</p>
<p>As a pivotal player in ARISE, UBITECH will:
</p>
<ul>
  <li>Lead the development of end-user functional requirements for the ARISE system.
  </li>
  <li>Shape the system specifications, ensuring seamless integration of AI and robotics.</li>
  <li>Contribute to the hardware and software architecture design, focusing on robot knowledge introspection and long-term Human-Robot Interaction HRI tasks.</li>
</ul>
              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article2