import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg from '../assets/img/img13.png'


import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article8 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  ARISE is Well-Spread Through Our Partner UBITECH!
                
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 20/02/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  30px 20px 0px'}} src={blogimg} title=""  width="460" height="291" />
               <p>We are excited to share that UBITECH, one of our esteemed partners, has published a press release on the ARISE project. 
               </p>
               <p>ARISE, funded under the Horizon Europe research and innovation programme, focuses on developing advanced robotics and AI technologies to revolutionize automation in energy and agriculture sectors. By integrating perception and control modules for reconfigurable manipulators, ARISE aims to tackle complex tasks like solar system installations and hydroponic farming, addressing labour shortages and boosting productivity.</p>
<p>As a pivotal player in ARISE, UBITECH’s role will be to lead the development of end-user functional requirements, shaping the system specifications for the ARISE system. UBITECH will also contribute significantly to the hardware and software architecture design, focusing on robot knowledge introspection methodologies and creating explanatory narratives for long-term HRI tasks. This collaboration highlights UBITECH’s commitment to advancing AI and robotics technology, reinforcing its position as a leader in innovative solutions for the future.</p>
 

  <p>Have a look at the <a href='https://ubitech.eu/ubitech-kicks-off-the-arise-research-and-innovation-action-to-propel-ai-and-robotics-forward-in-energy-and-agriculture/'>Press Release</a>.</p>

                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article8