import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import blogimg1 from '../assets/img/blogimg14.jpg'
import blogimg2 from '../assets/img/blogimg2.jpg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";

const Article1 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#64b052'}} className="post-title pt-3">
                  Kick-off-Meeting in Athens
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 20/02/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg1} title=""  width="420" height="291" />
                <p>
                We are very excited to announce the start of the engaging journey of the ARISE project consortium which was sealed with a kick-off meeting! </p>
<p>
On February 2nd 2024, in Athens, Greece, participants from all partners 
(<a href="https://hmu.gr/en/home/">Hellenic Mediterranean University</a>, <a href='https://www.santannapisa.it/en'>Sant’Anna School of Advanced Studies</a>, 
<a href='https://www.tu-darmstadt.de/'> Technical University of Darmstadt</a>, <a href='https://www.cea.fr/english'> CEA</a>, 
<a href='https://www.alsymex-alcen.com/'> ALSYMEX</a>, <a href='https://plaixus.com/'> PLAIXUS</a>, <a href='https://bendabl.com/'> BENDABL</a>, <a href='https://www.certh.gr/root.en.aspx'>Centre for Research and Technology Hellas</a>, 
<a href='https://www.csic.es/en'> Spanish National Research Council</a>, <a href='https://ubitech.eu/'> UBITECH</a>, 
<a href='https://www.probotica.hr/'> PROBOTICA</a>, <a href='https://telekinesis.ai/'> TELEKINESIS</a>, <a href='https://www.elvan.gr/en/'>ELVAN</a>, 
<a href='https://www.jepco.co.uk/'> JEPCO</a> and the <a href='https://www.twi-global.com/innovation-network/centres/innovation-centres/essex-innovation-centre'>Essex Innovation Centre </a> 
(essex.ai: a <a href='https://www.essex.ac.uk/'> University of Essex</a>–TWI partnership, represented by TWI Hellas on the project) came together to lay the groundwork and find out how to revolutionize the energy and agriculture sectors by leveraging AI and robotics to address pressing challenges, and usher in a new era of efficiency, productivity and sustainability.
</p>
<p>
Funded under the Horizon Europe research and innovation programme, ARISE aims to introduce a combination of perception and control modules for a reconfigurable, robotic manipulator. This will elevate the level of automation beyond what is currently possible for complex manipulation tasks. The project’s toolchain will be integrated and validated in real-world, use case scenarios, including installation and repair of solar systems, and transplanting and harvesting tasks in hydroponic farms.
</p> <p>
TWI Hellas, hosted the kick-off meeting as the Project Coordinator, overseeing all management arrangements and leading on project dissemination, impact analysis and policy recommendation development for the ARISE toolchain
                  </p>
                  <p> All partner organizations showcased their past and ongoing research and business ventures, emphasizing their technological capabilities crucial for project success. Following, there was a thorough discourse on forthcoming research advancements, key objectives, and milestones. In essence, the meeting provided an opportunity for all consortium members to deliberate on both technical and non-technical aspects, aligning strategies for achieving the best possible outcomes.    </p>           
              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article1