import React, {useState} from "react";
import "../../assets/scss/styles.scss"

import DarkFooter from "../../components/Footers/DarkFooter.js";
import Signature from "../signaturejs.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import essex from '../../assets/img/ARISE_Partners_Logos/AP15. ESSEX_innovation_centre.png'
import elvan from '../../assets/img/ARISE_Partners_Logos/P14. ELVAN_logo-white.jpg'
import tel from '../../assets/img/ARISE_Partners_Logos/P13. TEL_logo_no_background.png'
import pro from '../../assets/img/ARISE_Partners_Logos/P12. probotica_logo_01.png'
import ubt from '../../assets/img/ARISE_Partners_Logos/P11. UBT_logo_no_background.png'
import csic from '../../assets/img/ARISE_Partners_Logos/P10. CSIC.png'
import certh from '../../assets/img/ARISE_Partners_Logos/P9. CERTH_logo_no_background.png'
import bendabl from '../../assets/img/ARISE_Partners_Logos/bendabl.svg'
import plaixus from '../../assets/img/ARISE_Partners_Logos/PLAIXUS.png'
import alsymex from '../../assets/img/ARISE_Partners_Logos/P6. logo-alsymex+baseline.jpg'
import cea from '../../assets/img/ARISE_Partners_Logos/P5. CEA List.png'
import tud from '../../assets/img/ARISE_Partners_Logos/P4. TUD_logo_no_background.png'
import sssa from '../../assets/img/ARISE_Partners_Logos/sntana.png'
import hmu from '../../assets/img/ARISE_Partners_Logos/P2. HMU_Logo_SVG_not_available.png'
import twi from '../../assets/img/ARISE_Partners_Logos/P1. TWIHellas-logo.png'
import jepco from '../../assets/img/ARISE_Partners_Logos/AP16. JEPCO_logo_white.png'



import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import Modal from 'react-bootstrap/Modal';


function Partners() {
  const [AgenciaShow, setAgenciaShow] = useState(false);
  const [CerthShow, setCerthShow] = useState(false);
  const [PlaixusShow, setPlaixusShow] = useState(false);
  const [CommisariatShow, setCommisariatShow] = useState(false);
  const [DarmstadtShow, setDarmstadtShow] = useState(false);
  const [ScuolaShow, setScuolaShow] = useState(false);
  const [UbitechShow, setUbitechShow] = useState(false);
  const [ProboticaShow, setProboticaShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [elvanShow, setElvanShow] = useState(false);
  const [twiShow, setTwiShow] = useState(false);
  const [bendablShow, setBendablShow] = useState(false);
  const [jepcoShow, setJepcoShow] = useState(false);
  const [alsymexShow, setAlsymexShow] = useState(false);
  const [telekinisShow, setTelekinisShow] = useState(false);
  const [hmuShow, setHmuShow] = useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
 
        <section className="section section-about-us" id='partners'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#7dbf6d',marginTop:'50px'}}>Consortium</h2>
                <section class="section section-default mt-none mb-none">
<div class="container">

<strong>
<div class="row" id='twi'>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={twi} onClick={() => setTwiShow(true)}/>
<Modal 
        show={twiShow}
        onHide={() => setTwiShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>TWI Hellas <span> <a target="_blank" href="https://www.twi-hellas.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setTwiShow(false)}/>
        </Modal.Header>
        <Modal.Body>TWI Hellas is a wholly-owned Greek-based subsidiary of TWI, an independent research and technology organisation with physical offices in the UK, Europe, South East Asia, India, the Middle East, 
          Central Asia and the USA. The team specialised in the fields of advanced robotics, artificial intelligence, cloud, and digital engineering, develops state-of-the-art technologies for volume-efficient data processing, 
          model-driven data analysis, and large-scale data management to deliver:
</Modal.Body>
<Modal.Body>
  <ul>
    <li>State-of-the-art Machine Learning algorithms for spatiotemporal data processing (image, video) and analysis enabling step-changes in information extraction and machine awareness.</li>
    <li>Next-generation Human Machine Interfaces delivered seamlessly through web/mobile platforms for sophisticated tasks, from data processing to special-purpose robot control.</li>
    <li>Digital simulation models (Digital Twins) that update with input from multiple sources and change as their physical counterparts change to represent real-time status or infer their condition.
</li>
  </ul>
  <Modal.Body>To maximise the technological impact of collaborative R&D and industrial projects while boosting their smooth introduction to the market, TWI Hellas has assembled a dedicated team for market analysis, 
    project management, dissemination and exploitation.
</Modal.Body>

</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3" id='hmu'>
<div class="square-holder">
<img alt="" src={hmu} onClick={() => setHmuShow(true)}/>
<Modal 
        show={hmuShow}
        onHide={() => setHmuShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title>
        <h5 className="title" style={{color:'#303030'}}>ELLINIKO MESOGEIAKO PANEPISTIMIO
        <span> <a target="_blank" href="https://hmu.gr/en/home/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setHmuShow(false)}/>
        </Modal.Header>
        <Modal.Body>Hellenic Mediterranean University (HMU) is a new, qualitative, extroverted University with a strong international presence.
Our goal is to provide modern, comprehensive, quality education, as well as all those supplies that will help students in their professional career. To this end, we work systematically to update the curricula of our academic Departments so that they treat broad, modern scientific subjects with important professional perspectives. At the same time, we are constantly upgrading our student care services so that your integration into the academic environment is smooth and life in it is pleasant and constructive.
HMU’s STRENGTHS:
</Modal.Body>
<Modal.Body>
  <ul>
<li> Member of the European University ATHENA</li>
<li>For 2021, it offers remunerative scholarships (€2500 / semester) for the first successful candidates in HMU Departments (more)</li> 
<li>Constantly rising position in research compared to other regional universities</li> 
<li> HMU’s Departments offer studies in (unique) subjects that are not covered by other Universities in Crete or Southern Greece</li>
<li> Delivery in internships and better connection and high absorption rates of graduates in the labor market</li>
<li> 15 postgraduate study programs from all Faculties</li>
<li> It has the largest student residence</li>
<li> HMU’s Departments are located in beautiful cities of Crete</li>
</ul>
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-Sm-6 col-md-4 col-lg-3" id='sssa'>
<div class="square-holder">
<img alt="" src={sssa} onClick={() => setScuolaShow(true)}/>
<Modal centered show={ScuolaShow}
        onHide={() => setScuolaShow(false)}>
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        
          <Modal.Title><h5 className="title" style={{color:'#303030'}}>SCUOLA SUPERIORE DI STUDI UNIVERSITARI E DI PERFEZIONAMENTO S ANNA<span> <a target="_blank" href="https://www.santannapisa.it/en"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setScuolaShow(false)}/>
        </Modal.Header>
        <Modal.Body>Sant’Anna School of Advanced Studies was set up in 1987 as a result of the merger of the School of University Studies and Doctoral Research with the Sant’Anna Conservatoire. Both institutions could look back to a venerable tradition of study associated to the city of Pisa and the history of its university and colleges. As a public institution, the Sant'Anna School plays its role in favor of the community, proposing as a center of reference for avant-garde training, with paths of excellence.</Modal.Body>
<Modal.Body>The School’s mission is that of serving as a benchmark and quality public institution, attracting talents which are nurtured and educated, in order to take care of the world and to responsibly contribute to its cultural growth and sustainability. We are conscious that merit based on quality skills and commitment represents the true driver of development, the principal mechanism of social mobility, and the resource that we must implement in order to address the challenges of sustainability and justice that the world is currently facing.</Modal.Body>
      </Modal>
</div>
</div>


<div class="col-sm-6 col-md-4 col-lg-3" id='tud'>
<div class="square-holder">
<img alt="" src={tud} onClick={() => setDarmstadtShow(true)}/>
<Modal 
        show={DarmstadtShow}
        onHide={() => setDarmstadtShow(false)}
        aria-labelledby="example-modal-sizes-title-Plaixus"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>TECHNISCHE UNIVERSITAT DARMSTADT<span> <a target="_blank" href="https://www.tu-darmstadt.de/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setDarmstadtShow(false)}/>
        </Modal.Header>
        <Modal.Body>The Technical University of Darmstadt stands for excellent and relevant science. Global transformations – from the energy transition to Industry 4.0 to artificial intelligence – challenge us. We play a decisive role in shaping these profound change processes through outstanding findings and forward-looking study programs.
</Modal.Body>
<Modal.Body>
Since our founding in 1877, we have been one of the most internationally influenced universities in Germany; As a European Technical University, we see ourselves as committed to European values and European integration. We are at home in the Frankfurt-Rhine-Main metropolitan region. With our partners in the Alliance of Rhine-Main Universities, we are further developing this globally attractive scientific area.</Modal.Body>
        <Modal.Body>We bundle our cutting-edge research in three fields: Energy and Environment, Information and Intelligence, Matter and Materials. Our research and studies are characterized by the large, problem-centered interdisciplinarity between engineering, natural sciences, humanities and social sciences. We are in intensive and productive exchange with society, business and politics. This cooperative diversity results in long-term progress for global sustainable development.
</Modal.Body>

      </Modal>
</div>
</div>

<div class="col-Sm-6 col-md-4 col-lg-3" id='cea'>
<div class="square-holder">
<img alt="" src={cea} onClick={() => setCommisariatShow(true)}/>
<Modal 
        show={CommisariatShow}
        onHide={() => setCommisariatShow(false)}
        aria-labelledby="example-modal-sizes-title-Sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>CEA<span> <a target="_blank" href="https://www.cea.fr/english"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setCommisariatShow(false)}/>
        </Modal.Header>
        <Modal.Body>The CEA is a major research organisation working in the best interests of the French State, its economy and citizens. Thanks to its strong roots in fundamental research, it is able to provide tangible solutions in four key fields: 
        <br/><br/>
<ul>
<li> Low-carbon energy (nuclear and renewable)</li>
<li> Digital technology </li>
<li> Technology for medicine of the future </li>
<li> Defence and national security</li></ul>

<ul>
The CEA has adopted a very unique approach in the research and innovation sector, based on 5 pillars:<br/><br/>
<li> Its historical role in France's defence and national security strategy</li>
<li>Its ground-breaking strategy in research and innovation through its study of the atom</li> 
<li>Its capacity to handle projects, from the fundamental discovery of a concept through to its development</li> 
<li> Its strong support of start-ups that drive the development of breakthrough innovations</li>
<li> Its local presence in different regions, its open-minded approach, and its cooperative spirit. </li>
The CEA made the Top 100 Global Innovators list that recognises organisations having demonstrated above-the-bar innovation performance for the past 10 years  (Clarivate ranking). It is also the leading French organisation that registers the most patent applications in Europe according to the European Patent Office (EPO) 2021. 
</ul></Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={alsymex} onClick={() => setAlsymexShow(true)}/>
<Modal 
        show={alsymexShow}
        onHide={() => setAlsymexShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>Alsymex<span> <a target="_blank" href="https://www.alsymex-alcen.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setAlsymexShow(false)}/>
        </Modal.Header>
        <Modal.Body>ALSYMEX is a company of the French industrial group ALCEN.
</Modal.Body>
<Modal.Body>
ALSYMEX is specialized in the design and manufacture of complex systems for clients involved in Defense & Security, Energy, Aeronautics & Space and Large Scientific Instruments. 
ALSYMEX is actively contributing to the supply of critical components for the fusion project of ITER.
</Modal.Body>
  <Modal.Body>ALSYMEX has recently launched an initiative to explore opportunities in the integration of smart automation and robotics processes dedicated to dexterous manipulation 
    and inspection & maintenance missions of critical assets in challenging environments.
</Modal.Body>
      </Modal>
</div>
</div>


<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={plaixus} onClick={() => setPlaixusShow(true)}/>
<Modal 
        show={PlaixusShow}
        onHide={() => setPlaixusShow(false)}
        aria-labelledby="example-modal-sizes-title-Plaixus"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>Plaixus<span> <a target="_blank" href="https://plaixus.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setPlaixusShow(false)}/>
        </Modal.Header>
        <Modal.Body>PLAIXUS is an engineering team with a profound understanding of the challenges faced by Machine Learning practitioners across different industries.</Modal.Body>
<Modal.Body>We’ve been there ourselves. We’ve trained and deployed models for all sorts of applications. From text analysis to medical image processing. From process optimisation to robotic control.</Modal.Body>
<Modal.Body>
The harsh reality is that developing algorithms and deploying them for maximum impact are two different beasts. Often times regulation or bandwidth limitations necessitates that the code moves to the data instead of the other way around. Setting up the infrastructure to enable rapid development-testing-deployment iterations is key.
</Modal.Body>
<Modal.Body>
We craft the tools that enable APIs and complex AI pipelines to click together. We enable code and data to pace gracefully back and forth along the cloud-edge continuum. We empower ML practitioners to transverse the AI plexus with efficiency and elegance.
</Modal.Body>
      </Modal>
</div>
</div>


<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={bendabl} onClick={() => setBendablShow(true)}/>
<Modal 
        show={bendablShow}
        onHide={() => setBendablShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>bendabl<span> <a target="_blank" href="https://bendabl.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setBendablShow(false)}/>
        </Modal.Header>
        <Modal.Body>Bendabl is a high-tech SME based in Greece. The Bendabl team is composed of engineers with a passion for soft robotics and pneumatically actuated systems with more than 10 years of collective experience in the field. We believe that the future belongs to robots that can reach the pinnacle of adaptability, the very change of their physical properties through mechanically programmable metamaterials. Having accumulated deep expertise in pneumatically-actuated mechanisms and soft structure manufacturing using inherently compliant materials, we aspire to be the pioneers of this new generation of robots. To push the boundaries of their capability, to broaden the range of applications they can help with, and, most importantly, to flatten the learning curve of both developing and deploying them.

</Modal.Body>
      </Modal>
</div>
</div>


<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={certh} onClick={() => setCerthShow(true)}/>
<Modal 
        show={CerthShow}
        onHide={() => setCerthShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>Certh<span> <a target="_blank" href="https://www.certh.gr/root.en.aspx"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setCerthShow(false)}/>
        </Modal.Header>
        <Modal.Body>The Centre for Research and Technology Hellas is a legal entity governed by private law with non-profit status, supervised by GSRI of the Greek Ministry of Development. CERTH suggests one of the most important Research Centers in Greece with a main mission: The promotion of innovative research for the benefit of society mainly, towards the following fields:</Modal.Body>
<Modal.Body>
Clean energy, low/zero CO2 emission technologies, clean vehicle technologies, green hydrogen, climate change, artificial intelligence, advanced robotic systems, the internet of things, Industry 4.0, smart home infrastructure for the future, precision medicine, holistic health nutrition, holistic health-nutrition approach, autonomous vehicles, driving simulators, traffic control hubs, smart cities, precision agriculture, agro-robotics and circular economy, are the primal fields around which CERTH’s five (5) institutes are organized. Chemical Process & Energy Resources Institute - CPERI, Information Technologies Institute - ITI, Hellenic Institute of Transport - HIT, Institute of Applied Biosciences - INAB, and Bio-economy and Agro-Technology Institute - IBO, bring together more than 1500 people (engineers and scientists in their majority), in 7 regions and 9 cities of Greece.</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={csic} onClick={() => setAgenciaShow(true)}/>
<Modal 
        show={AgenciaShow}
        onHide={() => setAgenciaShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>AGENCIA ESTATAL CONSEJO SUPERIOR DE INVESTIGACIONES CIENTIFICAS<span> <a target="_blank" href="https://www.csic.es/en"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setAgenciaShow(false)}/>
        </Modal.Header>
        <Modal.Body>
The Institut de Robòtica i Informàtica Industrial (IRI) is a Joint Research Center of the Spanish National Research Council (CSIC) and the Technical University of Catalonia (UPC), which conducts basic and applied research in human-centered robotics and automatic control. The institute, founded in 1995, is a key player in the Spanish robotics and automatic control scenes, and a valued participant in a large number of international collaborations. Researchers from IRI involved in ARISE are affiliated with CSIC and are part of a specific research line at IRI — the Perception and Manipulation group; and they will develop the knowledge representation framework for ARISE. 

</Modal.Body>
<Modal.Body>
CSIC is the largest public research institution in Spain and one of the most renowned institutions in the European Research Area (ERA). It is affiliated to the Ministry of Science, Innovation and Universities and is the main agent responsible for implementing the Spanish System for Science, Technology and Innovation. CSIC currently has 124 research institutes and national centres distributed throughout Spain.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={ubt} onClick={() => setUbitechShow(true)}/>
<Modal 
        show={UbitechShow}
        onHide={() => setUbitechShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>UBITECH Hellas <span> <a target="_blank" href="https://ubitech.eu/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setUbitechShow(false)}/>
        </Modal.Header>
        <Modal.Body>UBITECH has delivered several mission-critical IT projects in several domains, such as financial, retail, banking, security, etc. In parallel, the company is active in several cutting-edge research projects mainly in the areas of cloud orchestration, digital security, risk management, as well as, knowledge engineering and data management. Through the synergy of commercial and research projects, the company has acquired significant and invaluable experience and technological know-how.</Modal.Body>
<Modal.Body>
To this end, all engineers of ours have high technological expertise and specialized know-how, which is internally spread through a synergetic knowledge transfer process. To this end, our engineers are continuously evolving their atomic competencies, while, in parallel, contributing in the evolution of their colleagues.  
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={pro} onClick={() => setProboticaShow(true)}/>
<Modal 
        show={ProboticaShow}
        onHide={() => setProboticaShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title>
        <h5 className="title" style={{color:'#303030'}}> PROBOTICA  <span> <a target="_blank" href="https://Probotica.gr/en/home/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setProboticaShow(false)}/>
        </Modal.Header>
        <Modal.Body> Probotica was established in 2019 with the combined experience of its founders in the fields of:<br/><br/>
          <ul>
<li> product development</li>
<li> project management and management of project organizations,</li>
<li> managing development projects and development teams.</li></ul>

The founders want to achieve a synergy of diverse but complementary skills, and offer the market superior services and innovative products.
We specialize in developing complex products and customized solutions in the fields of mechatronics and robotics. We successfully cover the fields of mechanical design, mechatronics, electronics, and software engineering.</Modal.Body>
<Modal.Body>
With our network of partners, we are able to offer a complete and customizable product development service, including everything from basic research, technology development and system testing, to product launch and operational support. </Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={tel} onClick={() => setTelekinisShow(true)}/>
<Modal 
        show={telekinisShow}
        onHide={() => setTelekinisShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>TELEKINESIS UG<span> <a href="https://telekinesis.ai/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setTelekinisShow(false)}/>
        </Modal.Header>
        <Modal.Body>We are developing the first AI technology, called Visual Robot Programming, that enables robots to autonomously learn behaviors by watching videos.
Our vision is to democratize robotics by empowering every individual to teach robots with our no-code solution. 
Our story began in the heart of the Intelligent Autonomous Systems (IAS) group at TU Darmstadt, Germany, amidst the robots in the lab.


</Modal.Body>
<Modal.Body>
Headed by Prof. Jan Peters, Ph.D., IAS is one of the leading Robot Learning groups in Europe with expertise in Robotics, Machine Learning, Reinforcement Learning, and Imitation Learning. It has pioneered numerous breakthrough algorithms, namely, Probabilistic Movement Primitives, Relative Entropy Policy Search, and Natural Actor-Critic, to solve complex manipulation tasks such as robot juggling, robot table tennis, and robot air hockey. The group is home to the brightest researchers in Machine Learning and Robotics.
</Modal.Body>
<Modal.Body>
In the midst of these great minds, a tiny dream emerged: develop an AI that enables robots to autonomously learn by watching videos. And thus, Telekinesis was born. 
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={elvan} onClick={() => setElvanShow(true)}/>
<Modal centered show={elvanShow}
        onHide={() => setElvanShow(false)}>
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        
          <Modal.Title><h5 className="title" style={{color:'#303030'}}>ELVAN<span> <a target="_blank" href="https://www.elvan.gr/en/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setElvanShow(false)}/>
        </Modal.Header>
        <Modal.Body>Around the world, the ELVAN brand stands for delivering certified quality cable trays, cable ladders and supports, designed and manufactured in-house.
For over 40 years customers from the construction, industrial and building sectors have trusted the experience and knowledge of the ELVAN brand.
</Modal.Body>
<Modal.Body>Lots of references in Greece, Europe, Middle East, North & West Africa and are a testament to the quality, performance and reliability of ELVAN  products and for the cooperation between ELVAN professionals and ELVAN customers.
Elvan cable support systems are the result of our experience of many thousands of kilometers of cable laying. From large projects to every day installations. 
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={essex} onClick={() => setSmShow(true)}/>
<Modal 
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>University of Essex<span> <a target="_blank" href="https://www.essex.ac.uk/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setSmShow(false)}/>
        </Modal.Header>
        <Modal.Body>The University of Essex (UESS) is gold-rated in the Teaching Excellence Framework 2017, and we're in the top 25 for research quality in The Times and Sunday Times Good University Guide. 
          We’re committed to excellence in research for the benefit of individuals, communities,
          and the industry, and we provide extensive open access to our research repository.
</Modal.Body>
<Modal.Body>The Essex Artificial Intelligence Innovation Centre brings together the research skills, expertise and technologies across our Faculty of Humanities, Science and Health, and Social Sciences to 
  address the grand challenges facing citizens, workers, and all those technologists in sectors undergoing digital transformation to create new – or modify existing - business processes, culture, and customer 
  experiences to meet changing business and market requirements. We have the ambition of developing a sustainable funding model for our research fit for the rigours of the emerging UK research environment. 
  We reach beyond the confines of academia as we have a clear mandate to work with business partners in the UK and overseas. We’re different because we bring a commercial mindset to our work. We start with
  and are responsive to business needs. We work hard to make sure they are getting the outputs they need when they need them. We’re not bound by tradition and offer fresh perspectives and innovation in all we do.

</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={jepco} onClick={() => setJepcoShow(true)}/>
<Modal 
        show={jepcoShow}
        onHide={() => setJepcoShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#7dbf6d'}}>
        <Modal.Title><h5 className="title" style={{color:'#303030'}}>JEPCO<span> <a target="_blank" href="https://www.jepco.co.uk/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#303030',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#303030',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setJepcoShow(false)}/>
        </Modal.Header>
        <Modal.Body>Based on the East Coast of England, JEPCO have been growing and supplying its customers with outstanding fresh produce since 1946.
Innovation is at the heart of the business and continued investment in conventional and organic varietal development, as well as cutting edge technologies, ensures that JEPCO remain ahead of the curve.
Innovation is truly at the heart of all that we do, from variety trials with seed houses, robotics trials with machinery manufacturers, to agronomic trials with industry bodies. We constantly strive to offer our customers, suppliers and ultimately the consumer, the most exciting, the most efficient and the most relevant products and processes available.
</Modal.Body>
<Modal.Body>
JEPCO provides a wide range of conventional and organic leaves and salad onions to the Manufacturing, Retail, Foodservice and Wholesale sectors. Also, we have 127 acres dedicated to two schemes that include reed bed recreation, new woodlands, pollen and nectar mixes and buffer strips for watercourses.</Modal.Body>
      </Modal>
</div>
</div>





















</div>
</strong>
</div>
</section>
              </Col>
            </Row>
            </Container>
            </section>
           
       
        <DarkFooter />
       
      </div>
    </>
  );
}

export default Partners;
