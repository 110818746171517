import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from "react-router-dom";
import Scroll from 'react-scroll'
import arise from './arise-hor.png'
import { HashLink } from "react-router-hash-link";
// reactstrap components
import {
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Button,
  Container,
  UncontrolledTooltip,
} from "reactstrap";


const ScrollLink = Scroll.ScrollLink
function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <Container style={{padding:'0'}}>
        
          <div className="navbar-translate">
            <NavbarBrand>
            <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/'><img src={arise} style={{width:'160px',padding:'0px 5px'}}></img></HashLink>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
              <UncontrolledDropdown>
  <DropdownToggle
    caret id='tet'
  >
    Home
  </DropdownToggle>
  <DropdownMenu dark>
    <DropdownItem  style={{background:'#ffffff12'}}>
    <HashLink smooth  offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/#mission'>Mission Statement</HashLink>           
    </DropdownItem>
    <DropdownItem style={{background:'#ffffff12'}}>
    <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/#consortium'>Consortium</HashLink>    
    </DropdownItem>
    <DropdownItem style={{background:'#ffffff12'}}>
    <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/#latestnews'>Latest News</HashLink>
           
    </DropdownItem>
    
    
  </DropdownMenu>
  
</UncontrolledDropdown >
              </NavItem>
              <UncontrolledDropdown >
  <DropdownToggle
    caret id='tet'
  >
    Project
  </DropdownToggle>
  <DropdownMenu dark>
    <DropdownItem style={{background:'#ffffff12'}}>
              <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/project#challenges'>Current Challenges</HashLink>      
    </DropdownItem>
    <DropdownItem style={{background:'#ffffff12'}}>
              <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/project#objectives'>Objectives</HashLink>
    </DropdownItem>
    <DropdownItem style={{background:'#ffffff12'}}>
              <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/project#results'>Expected results</HashLink>
    </DropdownItem>
    
    
  </DropdownMenu>
  
</UncontrolledDropdown>
              <NavItem>
                <NavLink style={{fontSize:'14px'}}>
                <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/consortium#partners'>Consortium</HashLink>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown >
  <DropdownToggle
    caret id='tet'
  >
    Newsroom
  </DropdownToggle>
  <DropdownMenu dark>
    <DropdownItem style={{background:'#ffffff12'}}>
              <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/news#news'>News</HashLink>      
    </DropdownItem>
    <DropdownItem style={{background:'#ffffff12'}}>
              <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/news#newsletter'>Newsletter</HashLink>
    </DropdownItem>
    
    
  </DropdownMenu>
  
</UncontrolledDropdown>
      
              <NavItem>
              <UncontrolledDropdown>
              <DropdownToggle id='tet' caret>
    Outcomes
  </DropdownToggle>

  <DropdownMenu dark>
    <DropdownItem style={{background:'#ffffff12'}}>
    <NavLink>
              <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}}to='/outcomes#publications'>Publications</HashLink>
              </NavLink>
    </DropdownItem>
    <DropdownItem style={{background:'#ffffff12'}}>
    <NavLink>
              <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}}to='/outcomes#deliverables'>Public Deliverables</HashLink>
              </NavLink>
    </DropdownItem>
    <DropdownItem style={{background:'#ffffff12'}}>
    <NavLink>
              <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}}to='/outcomes#material'>Promotional Material</HashLink>
              </NavLink>
    </DropdownItem>
    
    
  </DropdownMenu>
  </UncontrolledDropdown>
             
              </NavItem>
              <NavItem>
              <NavLink  style={{fontSize:'14px'}}>           
                <HashLink smooth offset={() => 100} style={{fontSize:'14px',textDecoration:'none'}} to='/#contact'>Contact</HashLink>
                </NavLink>
              </NavItem>

            
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
