import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import blogimg1 from '../assets/img/blogimg1.jpg'
import blogimg3 from '../assets/img/blogimg15.png'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article2 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  Alongside Adra-e. Towards AI-driven autonomous robots.            
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 23/03/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg3} title=""  width="520" height="291" />
                <p>The ARISE Project recently participated in a significant launch event aimed at advancing research and innovation excellence in the fields of AI, Data, and Robotics. The event took place on February 22, 2024, from 9:00 am to 2:00 pm. During this event, ARISE presented its objectives, technologies, and innovative solutions designed to address the problems within the Solar and Agriculture farms.</p>
<p>
Through a compelling video presentation, ARISE delved into the environmental and societal challenges associated with the two sectors, backed by relevant statistics and real-world examples. The presentation also highlighted how the project plans to leverage AI, Robotics and a suite of cutting-edge technologies to tackle all related issues effectively. Moreover, the video provided insights into the anticipated impacts of ARISE's implementation across various domains, including science, economy, society, and sustainability.
</p>
<p>
Participants at the launch event gained valuable insights into the ARISE project and its mission. The event fostered collaboration among various projects, supported by the European Commission and Horizon Europe. ARISE was presented under the section: Novel paradigms and approaches, towards AI-driven autonomous robots (AI, data and robotics partnership) - RIA -  HORIZON-CL4-2023-DIGITAL-EMERGING-01-01, alongside other initiatives funded: IRE, INVERSE and RoboSapiens, all contributing to a more sustainable future through the development of AI and robotics solutions.
</p> <p>
To view the ARISE project presentation, click on the following link:
</p>
<a id='hyperlink' href="https://www.youtube.com/watch?v=sF3skkprnvM&t=1s">ARISE Project Presentation</a>                
              
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article2