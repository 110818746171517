import React, { Component } from "react";
import './carousel.scss'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import essex from '../assets/img/ARISE_Partners_Logos/AP15. ESSEX_innovation_centre.png'
import elvan from '../assets/img/ARISE_Partners_Logos/P14. ELVAN_logo-white.jpg'
import tel from '../assets/img/ARISE_Partners_Logos/P13. TEL_logo_no_background.png'
import pro from '../assets/img/ARISE_Partners_Logos/P12. probotica_logo_01.png'
import ubt from '../assets/img/ARISE_Partners_Logos/P11. UBT_logo_no_background.png'
import csic from '../assets/img/ARISE_Partners_Logos/P10. CSIC.png'
import certh from '../assets/img/ARISE_Partners_Logos/P9. CERTH_logo_no_background.png'
import bendabl from '../assets/img/ARISE_Partners_Logos/bendabl.svg'
import plaixus from '../assets/img/ARISE_Partners_Logos/PLAIXUS.png'
import alsymex from '../assets/img/ARISE_Partners_Logos/P6. logo-alsymex+baseline.jpg'
import cea from '../assets/img/ARISE_Partners_Logos/P5. CEA List.png'
import tud from '../assets/img/ARISE_Partners_Logos/P4. TUD_logo_no_background.png'
import sssa from '../assets/img/ARISE_Partners_Logos/sntana.png'
import hmu from '../assets/img/ARISE_Partners_Logos/P2. HMU_Logo_SVG_not_available.png'
import twi from '../assets/img/ARISE_Partners_Logos/P1. TWIHellas-logo.png'
import jepco from '../assets/img/ARISE_Partners_Logos/AP16. JEPCO_logo_white.png'
import { HashLink } from "react-router-hash-link";


export default class CenterMode extends Component {
	render() {
	const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay:1,
      slidesToShow: 5,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 2000,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1324,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
		{
			breakpoint: 400,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1
			}
		  }
      ]
    };
		return (
			<div className="container">
				<link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
				<style>{cssstyle}</style>

				<Slider {...settings}>
				<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={twi} alt="" style={{marginTop:'20%'}}/>
					</HashLink>
					</div>
					</li>
				
				<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={hmu} alt="" />
					</HashLink>
						</div>
					</li>
				

					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={sssa} style={{width:'200px'}}alt="" style={{marginTop:'25%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={tud} alt="" style={{marginTop:'25%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={cea} alt="" style={{marginTop:'20%'}}/>
					</HashLink>
					</div>
					</li>

					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={alsymex} alt="" style={{marginTop:'25%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={plaixus} alt="" style={{marginTop:'10%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={bendabl} alt="" style={{marginTop:'25%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={certh} alt="" style={{marginTop:'30%'}}/>
					</HashLink>
					</div>
					</li>
				
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={csic} alt="" style={{marginTop:'30%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={ubt} alt="" style={{marginTop:'30%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={pro} alt="" style={{marginTop:'25%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={tel} alt="" style={{marginTop:'30%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={elvan} alt="" style={{marginTop:'30%'}}/>
					</HashLink>
					</div>
					</li>

					<li>
					<div  id='correctionlist'>
					<div class="circle">
						<span>
						</span>
						</div>
					<HashLink  to="/consortium">
					  <img id='carousimg' src={essex} alt="" style={{marginTop:'25%'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium">
          <img id='carousimg' src={jepco} alt="" />
					</HashLink>
					</div>
					</li>
				

				
					
				
				
				
					


				</Slider>
			</div>
		);
	}
}

const cssstyle = `
.container {
  margin: 0 auto;
  padding: 0px 40px 40px 40px;
}
# {
	color: #fff;
	    font-size: 36px;
	    margin-left: auto;
	    margin-right: auto;
	    display: block;
	    max-width: 140px;
	    min-width: 90px;
	    line-height: 100px;
	    /* margin: 10px; */
	    margin-top: -194px;
	    margin-left: 29px;
	    /* padding: 2%; */
	    /* z-index: 1000; */
	    position: fixed;
	    text-align: center;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
`
