import React from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Map from '../map.js'
import letter from './newsletter.png'


import DarkFooter from "../../components/Footers/DarkFooter.js";
import BlogList from "../../components/BlogList";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";



function News() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='news'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#7dbf6d',marginTop:'50px'}}>News</h2>
               
              </Col>
              <BlogList/>
            </Row>
            </Container>
            </section>
           
            <section id='newsletter'>
        <div className="section section-team text-center" style={{backgroundColor:'#88bf7b'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title"  style={{color:'#ededed'}}>Newsletter</h2>
             
             <ul>
              <li style={{float:'left', color:'whitesmoke', listStyle:'none' }}><img src={letter} style={{width:'50px',marginRight:'10px',marginTop:'-30px'}}/>
              <a style={{fontSize:'19px'}}id='hyper' target="_blank" href='https://us21.campaign-archive.com/?u=f794e711ea6323a9cd6e8281b&id=69e71f0906'>1st Newsletter</a></li>
             </ul>
              </Col>
            </Row>
            </Container>
        </div>
        </section>
        <DarkFooter />
        
      </div>
    </>
  );
}

export default News;
