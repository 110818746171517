import React from "react";
import "../../assets/scss/styles.scss"
import DarkFooter from "../../components/Footers/DarkFooter.js";
import snake from '../../assets/img/snake.svg'
import base from '../../assets/img/base.svg'
import decision from '../../assets/img/decision.svg'
import flex from '../../assets/img/flex.svg'
import goal from './goal.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faLinkedinIn, faXTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons'

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";



function ProjectPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
 
        <section className="section section-about-us" id='challenges'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#7dbf6d',marginTop:'50px'}}>Current Challenges</h2>
               <ul style={{textAlign:'left',marginLeft:'50px',listStyleType:'none'}}>
                <li id='list3'>Improve productivity and supply chain efficiency in hazardous conditions.</li>
 <li id='list3'>Prioritise health and safety by minimising long-duration tasks using robotics.</li>
 <li id='list3'>Foster human-robot collaboration in everyday working environments.</li>
 <li id='list3'>Develop multipurpose robots to address labor shortages.</li>
 <li id='list3'>Enhance robotics data collection and analysis for improved decision-making.</li>
 <li id='list3'>Implement human-like senses in robots for intelligent decision-making.</li>
 <li id='list3'>Increase investment in AI and assess the return on investment.</li>
 <li id='list3'>Promote  scalability of AI-driven robotics for business integration.</li>
 <li id='list3'>Align with the 2030 Climate Targets for energy efficiency and green job creation.</li>						
               </ul>
               
              </Col>
            </Row>
            </Container>
            </section>
            <section id='objectives'>
        <div className="section section-team text-center" style={{backgroundColor:'#303030'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#7dbf6d'}}>Objectives</h2>
             
              <ul id='ul2'>
              <li style={{marginBottom:'10px'}}><div className="d-flex align-items-center">
                  <div>
                      <div style={{width:'44px'}}>
                      <img style={{width:'40px'}} src={goal}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{color:'whitesmoke',textAlign:'justify',marginLeft:'20px'}}>Develop a novel, pneumatic-based reconfigurable manipulator with advanced soft end-effectors capable of operating in environments with high risk of dust or water ingression while carrying out tasks involving both
high forces/torques and delicate, complex manipulation</p>
                    </div>
                  </div></li>
<br/>

<li style={{marginBottom:'10px'}}><div className="d-flex align-items-center">
                  <div>
                      <div style={{width:'44px'}}>
                      <img style={{width:'40px'}} src={goal}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{color:'whitesmoke',textAlign:'justify',marginLeft:'20px'}}>Develop a Hierarchical Imitation Learning module, grounded on acquired knowledge alongside task
planning algorithms with reactive planning capabilities including human-robot interaction</p>
                    </div>
                  </div></li>
<br/>


<li style={{marginBottom:'10px'}}><div className="d-flex align-items-center">
                  <div>
                      <div style={{width:'44px'}}>
                      <img style={{width:'40px'}} src={goal}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{color:'whitesmoke',textAlign:'justify',marginLeft:'20px'}}>Develop an Ontological framework for Knowledge Representation to enable robust and fault-tolerant
collaboration and autonomous task completion through reasoning based on domain-specific fact understanding</p>
                    </div>
                  </div></li>
<br/>



<li style={{marginBottom:'10px'}}><div className="d-flex align-items-center">
                  <div>
                      <div style={{width:'44px'}}>
                      <img style={{width:'40px'}} src={goal}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{color:'whitesmoke',textAlign:'justify',marginLeft:'20px'}}>Develop a cognitive algorithm module encompassing Active Perception, Semantic Mapping and Localisation capabilities to fuse and orchestrate perception modalities in a dynamic context-aware that will enable identification changes in the environment and autonomous operation for longer periods while
maintaining trustworthiness and dependability</p>
                    </div>
                  </div></li>
<br/>
<li style={{marginBottom:'10px'}}><div className="d-flex align-items-center">
                  <div>
                      <div style={{width:'44px'}}>
                      <img style={{width:'40px'}} src={goal}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{color:'whitesmoke',textAlign:'justify',marginLeft:'20px'}}> Establish an edge-native, resource-optimised and automated computing infrastructure able to support
dynamic computing at the Cloud-Edge continuum realising an ecosystem where ML-models for new tasks and/or applications are efficiently updated deployed on-board the robot or in a distributed manner.</p>
                    </div>
                  </div></li>
                           </ul>        
              </Col>
            </Row>
            </Container>
        </div>
        </section>
        <section id='results'>
        <div className="section section-team text-center" style={{backgroundColor:'whitesmoke'}}>
        <Container>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#7dbf6d'}}>Expected results</h2>
             
              <ul id='ul2'>
                <li id='list'>Two reconfigurable pneumatic-based robotic manipulators mounted on a mobile robotic platform</li><br/>
                <li id='list'>Novel soft end-effectors with variable stiffness that will allow for a diverse set of manipulation tasks</li><br/>
                <li id='list'>A Robotic perception module comprising 3D vision algorithms</li><br/>
                <li id='list'>A Localisation and Mapping module that will allow the robot to accurately identify its position within the environment</li><br/>
                <li id='list'>A Semantic Mapping module powered by scene understanding algorithms</li><br/>
                <li id='list'>A Knowledge Representation framework that will capture important information regarding objects’ properties and relationships</li><br/>
                <li id='list'>A Hierarchical Imitation Learning framework for acquiring robotic skills to accomplish complex tasks directly from human demonstrators</li><br/>
                <li id='list'>A Human-Interaction conditioned Path and Task planning module enabling reactive robot control</li><br/>
                <li id='list'>An edge-AI framework for deploying Machine Learning models and computer vision algorithms at the edge in a streamlined fashion</li><br/>
                <li id='list'>Two demonstrators in solar and hydroponic farms</li><br/>
              
            </ul>        
              </Col>
            </Row>
            </Container>
        </div>
        </section>
        <DarkFooter />
        
      </div>
    </>
  );
}

export default ProjectPage;
