import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg4 from '../assets/img/3news.jpg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article2 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  Advancing the Arise Project: ALSYMEX's visit to “Les Nouvelles Fermes”         
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                15/06/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg4} title=""  width="240" height="171" />
                <p>
                Our partner ALSYMEX recently visited Les Nouvelles Fermes, unveiling promising advancements for the Arise project!
</p>
<p>
Paul Morel, co-founder and Technical Director, provided an insightful tour of their 5,000 m² hydroponic farm. He demonstrated the hydroponic lettuce transplanting and harvesting process, highlighting the potential of autonomous systems.
</p>
<b>Arise Solution Highlights:
</b>
<ul>
  <li>Reducing lettuce loss rates</li>
  <li>Boosting production capacity without relying on human labor</li>
</ul>

<b>Key Benefits of Autonomous Harvesting:
</b>
<ul>
  <li>Mitigate manual labor shortages in the EU
  </li>
  <li>Scale eco-friendly cultivation methods, ensuring food security
  </li>
  <li>Reduce lettuce loss rates</li>
  <li>Boost production capacity without relying on human labor</li>
</ul>
<p>Les Nouvelles Fermes plans to expand by 2025-2026, with an estimated €3-4M investment and installation of PV solar panels.</p>

<b>Upcoming Events
</b>
<ul>
  <li>GreenTech Amsterdam: 11-13 June 2024
  </li>
  <li>Sival Anger France: 14-16 January 2025
  </li>
</ul>
<p>For more information, visit <a href='https://www.lesnouvellesfermes.com/'>Les Nouvelles Fermes</a>.</p>


                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article2